'use strict';

/* exported
  menuSupplierApiCtrl
 */
function menuSupplierApiCtrl($scope, user, supplierApiService, appConfig) {

  $scope.supplierApiService = supplierApiService;
  $scope.user = user;
  $scope.appConfig = appConfig;
  $scope.selectedDate = new Date();
  $scope.selectedNextDate = new Date();
  $scope.selectedNextDate.setDate($scope.selectedNextDate.getDate()+1);

  $scope.$watch('supplierApiService.selectedApiSupplier', function(){
    supplierApiService.transfers = [];
    supplierApiService.operatorsBulk = [];
    supplierApiService.selectedOperator = null;
    supplierApiService.updateDataSupplierChange();
  });

}

'use strict';

/* exported
 supplierApiCtrl
 */

function supplierApiCtrl($rootScope, $scope, user, InventoryRestangular, supplierApiService, rideRulesService) {
  $scope.supplierApiService = supplierApiService;
  $scope.user = user;
  $scope.rideRuleLevel = {type: 'Supplier'};
  const itemsPerPage = 50;

  $scope.showUnmappedCompanies = false;
  $scope.companySearchText = '';
  $scope.citySearchText = '';
  $scope.currentPage = 1;
  $scope.itemsPerViewPage = 100;


  $scope.updateCheckBoxFilter = function() {
    $scope.showUnmappedCompanies = !$scope.showUnmappedCompanies;
    $scope.updateFilteredCompanies();
  }

  $scope.updateSearchText = function(value) {
    $scope.companySearchText = value;
    $scope.updateFilteredCompanies();
  }

  $scope.updateCitySearchText = function(value) {
    $scope.citySearchText = value;
    $scope.updateFilteredCities();
  }


  $scope.filterByCheckbox = function(company) {
    if($scope.showUnmappedCompanies) {
      return true;
    }
    const hasBookawayData = company.bookawayData && Object.keys(company.bookawayData).length > 0;
    return hasBookawayData;
  };
  
  $scope.filterBySearchText = function(company) {
    if (!$scope.companySearchText) return true; 
  
    const searchTextLower = $scope.companySearchText.toLowerCase();
  
    const matchesBookawayData = company.bookawayData.companyName &&
      company.bookawayData.companyName.toLowerCase().includes(searchTextLower);
    
    let matchesSupplierData = company.supplierData &&
      company.supplierData.some(data => data.companyName && data.companyName.toLowerCase().includes(searchTextLower));
    
    if(!matchesBookawayData && !matchesSupplierData) {
      matchesSupplierData = company.supplierData &&
      company.supplierData.some(data => data.name && data.name.toLowerCase().includes(searchTextLower));
    } 
    
    return matchesBookawayData || matchesSupplierData;
  };

  $scope.filterByCitySearchText = function(city) {
    if (!$scope.citySearchText) return true; 
  
    const searchTextLower = $scope.citySearchText.toLowerCase();
  
    const matchesCity = city.bookawayData.cityName &&
      city.bookawayData.cityName.toLowerCase().includes(searchTextLower);

      const matchesSupplierData = city.supplierData &&
      city.supplierData.some(data => data.cityName && data.cityName.toLowerCase().includes(searchTextLower));  
    
    return matchesCity || matchesSupplierData;
  }
  
  $scope.updateFilteredCompanies = function() {
  
    if (!Array.isArray($scope.supplierApiService.companyMapping)) {
      $scope.filteredCompaniesList = [];
      return;
    }
  
    
    $scope.filteredCompaniesList = $scope.supplierApiService.companyMapping.filter(company => {
      const checkboxResult =  $scope.filterByCheckbox(company);

      const searchResult = $scope.filterBySearchText(company);
  
      const finalResult = checkboxResult  && searchResult;
  
      return finalResult;
    });

    $scope.hasCountryCode = $scope.filteredCompaniesList.some(company => 
      company.supplierData.some(companyMapping => companyMapping.countryCode)
    );
  
    $scope.currentPage = 1;
  };

  $scope.updateFilteredCities = function() {
  
    if (!Array.isArray($scope.supplierApiService.cityMapping)) {
      $scope.filteredCitiesList = [];
      return;
    }
    
    $scope.filteredCitiesList = $scope.supplierApiService.cityMapping.filter(city => {
      return $scope.filterByCitySearchText(city);
    });
  
    $scope.currentPage = 1;
  };
  

  $scope.$watch('supplierApiService.companyMapping', function(newVal) {
    if (newVal) {
      if (document.getElementById('companySearchInput')) {
        document.getElementById('companySearchInput').value = '';
      }
      $scope.companySearchText = '';
      $scope.updateFilteredCompanies();
    }
  });

  $scope.$watch('supplierApiService.cityMapping', function(newVal) {
    if (newVal) {
      if (document.getElementById('citySearchInput')) {
        document.getElementById('citySearchInput').value = '';
      }
      $scope.citySearchText = '';
      $scope.updateFilteredCities();
    }
  });
  
  $scope.$watch('supplierApiService.selectedOperator', function () {
    $scope.showOperator = !supplierApiService.selectedOperator;
  });

  $scope.mappingsFilterDropdownOptions = [
    {label: 'All', value: 'all'},
    {label: 'Fully mapped', value: 'fullmapped'},
    {label: 'Not mapped', value: 'notmapped'}
  ];
  
  $scope.filters = {
    filterByDepartureCity: '',
    filterByArrivalCity: '',
    filterByDepartureCountryName: '',
    filterByVehicle: '',
    filterByMappings: $scope.mappingsFilterDropdownOptions[0].value,
  };

  $scope.filterByMappingsChange = function() {
    $rootScope.$broadcast('filterByMappingsChange', $scope.filters.filterByMappings);
  }

  function filterChanged() {
    $scope.filters.offset = 0;
    $scope.filters.limit = itemsPerPage;
    $scope.filters.currentPage = 1;
    supplierApiService.fetchInventoryWithPagination($scope.filters).then((data) => {
      supplierApiService.transfers = data;
      supplierApiService.filterByMappings = $scope.filters.filterByMappings;
    });
  }

  $scope.filterChangedWithDebounce = _.debounce(filterChanged, 700);

  $rootScope.$on('mappingPageChanged', function(event, data) {
    $scope.filters.offset = (data - 1) * itemsPerPage;
    $scope.filters.limit = itemsPerPage;
    $scope.filters.currentPage = data;
    supplierApiService.fetchInventoryWithPagination($scope.filters).then((data) => {
      supplierApiService.transfers = data;
      supplierApiService.filterByMappings = $scope.filters.filterByMappings;
    });
  });

}

'use strict';

/* exported
 supplierApiProvider
 */

function supplierApiProvider() {
  this.$get = ['InventoryRestangular', '$rootScope', 'StringHelperService', 'moment', '$uibModal', 'TransferMappingEditService', 'RideRulesService', function (InventoryRestangular, $rootScope, StringHelperService, moment, $uibModal, TransferMappingEditService, RideRulesService) {
    var supplierApiService = {
      supplierList: [],
      activeTab: {},
      selectedApiSupplier: null,
      selectedBookawayCompany: {},
      selectedFromCountry: {},
      selectedToCountry: {},
      cityMapping: {},
      companyMapping: {},
      mappedCompanies:[],
      unmmapedCompanies:[],
      filters: {},
      supplierCities: [],
      supplierCompanies: [],
      supplierUnmappedCompanies:[],
      supplierCountries: [],
      lastSearchedCountries: {},
      supplierVehicleTypes: [],
      supplierVehicleClasses: [],
      selectedOperator: null,
      filteredTrips: [],
      operatorsBulk: [],
      filterByVehicleClass: '',
      filteredVehicleClasses: [],
      transfers: [],
      rulesAttribute: [],
      rulesAttributeFilters: {},
    };

    supplierApiService.supplierList = [];
    if (window.user.shouldSeeFullAdmin) {
      InventoryRestangular.one('/v1/supplier-api/general/all-suppliers').get().then(function (results) {
        supplierApiService.supplierList = results;
      });
    }

    supplierApiService.setActiveTab = function (index) {
      this.activeTab = index;
    };

    function performValidation(objectToCheck) {
      const errors = [];
      var valid = true;
      Object.keys(objectToCheck).forEach(function (key) {
        var item = objectToCheck[key];
        if (!item && item !== 0) {
          errors.push('Error: ' + key + ' is required');
          valid = false;
        }
      });
      return {valid: valid, errors: errors};
    }

    function handleSuccess(message) {
      console.log('success: ' + message);
      $rootScope.$emit('notify', {type: 'success', title: 'Success', message: message});
    }

    function handleError(err) {
      var message = '';
      if (_.isArray(err)) {
        message = err.join('<br>');
      } else {
        message = _.get(err, 'data.message') || err.message || '';
      }
      console.error('failed operation with error: \n' + message.replace(/<br>/g, '\n'));
      if(message !== 'Closed Modal') {
        $rootScope.$emit('notify', {type: 'error', title: 'Error', message: message});
      }
    }

    function refreshCityMappings() {
      InventoryRestangular.all('v1/supplier-api/' + supplierApiService.selectedApiSupplier.name)
        .get('cities-mapping')
        .then(function (citiesMapping) {
          supplierApiService.cityMapping = citiesMapping;
        })
        .catch(handleError);
    }

    function getCompanyMappings(supplierApiName) {
      return InventoryRestangular.all('v1/supplier-api/' + supplierApiName)
      .get('companies-mapping');
    }

    function refreshCompanyMappings() {
      getCompanyMappings(supplierApiService.selectedApiSupplier.name)
        .then(function (companiesMapping) {
          supplierApiService.companyMapping = companiesMapping;
          supplierApiService.unmmapedCompanies = companiesMapping.filter((company)=>!Object.keys(company.bookawayData).length)
          supplierApiService.mappedCompanies = companiesMapping.filter((company)=>!!Object.keys(company.bookawayData).length)
          supplierApiService.supplierUnmappedCompanies = supplierApiService.unmmapedCompanies.map((company)=>{
            return{
              id:company.supplierData[0].id,
              name:company.supplierData[0].name,
              companyMapping:{
                companyId: company.supplierData[0].id,
                companyName: company.supplierData[0].name
              }
            }
          })
        }).catch(handleError);
    }

    function refreshVehicleTypeMappings() {
      InventoryRestangular.all('v1/supplier-api/' + supplierApiService.selectedApiSupplier.name)
        .get('vehicle-types-mapping')
        .then(function (vehicleTypeMapping) {
          supplierApiService.vehicleTypeMapping = vehicleTypeMapping;
        })
        .catch(handleError);
    }

    function refreshVehicleClassMappings() {
      InventoryRestangular.all('v1/supplier-api/' + supplierApiService.selectedApiSupplier.name)
        .get('vehicle-classes-mapping')
        .then(function (vehicleClassMapping) {
          supplierApiService.vehicleClassMapping = vehicleClassMapping;
          supplierApiService.filteredVehicleClasses = vehicleClassMapping;
        })
        .catch(handleError);
    }


    function updateDataSupplierChange() {
      supplierApiService.cityMapping = [];
      supplierApiService.companyMapping = [];
      supplierApiService.vehicleTypeMapping = [];
      supplierApiService.vehicleClassMapping = [];

      supplierApiService.noResults = false;
      supplierApiService.supplierAToBTransfers = [];
      supplierApiService.supplierBToATransfers = [];
      supplierApiService.companiesSelectorObject = {};
      supplierApiService.routeLines = [];

      supplierApiService.supplierCities = [];
      supplierApiService.supplierCompanies = [];

      supplierApiService.filters = {};
      supplierApiService.rulesAttribute = [];
      supplierApiService.ruleCategories = RideRulesService.getRuleCategories();
      supplierApiService.rulesAttributeFilters = {
        category: supplierApiService.ruleCategories[0],
      };

      if (supplierApiService.selectedApiSupplier) {
        refreshCityMappings();
        refreshCompanyMappings();
        refreshVehicleTypeMappings();
        refreshVehicleClassMappings();
        if (supplierApiService.selectedApiSupplier.isOnline) {
          RideRulesService.getRules(supplierApiService);
        }
      }
    }

    function updateDataOverviewOperatorChange() {
      supplierApiService.rulesAttribute = [];
      supplierApiService.ruleCategories = RideRulesService.getRuleCategories();
      supplierApiService.rulesAttributeFilters.category = supplierApiService.ruleCategories[0];
      RideRulesService.getRules(supplierApiService);
    }

    function getSearchCountriesCode() {
      const fromCountryCode = _.get(supplierApiService, 'selectedFromCountry.iso2code');
      const toCountryCode = _.get(supplierApiService, 'selectedToCountry.iso2code');

      const validationResult = performValidation({fromCountryCode: fromCountryCode, toCountryCode: toCountryCode});
      if (!validationResult.valid) {
        handleError(validationResult.errors);
        return {};
      }

      return {fromCountryCode: fromCountryCode, toCountryCode: toCountryCode};
    }

    function performCountryToCountryTransferSearchFromRemote() {
      const searchCountriesCodeResults = getSearchCountriesCode();
      const fromCountryCode = searchCountriesCodeResults.fromCountryCode;
      const toCountryCode = searchCountriesCodeResults.toCountryCode;
      if (!fromCountryCode || !toCountryCode) {
        return;
      }
      InventoryRestangular.all('v1/supplier-api/' + supplierApiService.selectedApiSupplier.name)
        .get('countries-transfer-mapping-from-remote', {fromCountryCode: fromCountryCode, toCountryCode: toCountryCode})
        .then(function (result) {
          $rootScope.$emit('notify', {type: 'success', title: 'Success', message: 'Job is: ' + result.status + ', ' + result.pairsDone + '/' + result.totalPairs });
        }).catch(handleError);
    }

    function performCountryToCountryTransferSearch() {

      const searchCountriesCodeResults = getSearchCountriesCode();
      const fromCountryCode = searchCountriesCodeResults.fromCountryCode;
      const toCountryCode = searchCountriesCodeResults.toCountryCode;

      if (!fromCountryCode || !toCountryCode) {
        return;
      }
      // init
      supplierApiService.transfers = [];

      InventoryRestangular.all('v1/supplier-api/' + supplierApiService.selectedApiSupplier.name)
        .get('countries-transfer-mapping', {fromCountryCode: fromCountryCode, toCountryCode: toCountryCode})
        .then(function (results) {
          supplierApiService.transfers = results;
          supplierApiService.lastSearchedCountries = {
            countryA: supplierApiService.selectedFromCountry.name,
            countryB: supplierApiService.selectedToCountry.name,
          }
        }).catch(handleError);
    }

    function performTransferSearch(selectedDateInDateFormat, daysInAdvance) {
      let selectedDate;
      if (selectedDateInDateFormat){
        selectedDate = moment(selectedDateInDateFormat).format("YYYY-MM-DD");
      }
      const fromCityId = _.get(supplierApiService, 'filters.departure._id');
      const toCityId = _.get(supplierApiService, 'filters.arrival._id');
      const supplierApiName = supplierApiService.selectedApiSupplier.name;
      const validationResult = performValidation({fromCityId: fromCityId, toCityId: toCityId});
      if (!validationResult.valid) {
        handleError(validationResult.errors);
        return;
      }

      // init
      supplierApiService.supplierAToBTransfers = [];
      supplierApiService.supplierBToATransfers = [];
      supplierApiService.companiesSelectorObject = {};
      supplierApiService.filters.selectedSupplierCompany = null;
      supplierApiService.filters.AtoBTransfers = [];
      supplierApiService.filters.BtoATransfers = [];
      supplierApiService.rulesAttributeFilters.category = RideRulesService.defaultRuleCategory;
      supplierApiService.routeLines = [];

      // aToB
      TransferMappingEditService.performTransferSearch(selectedDate, daysInAdvance, fromCityId, toCityId, supplierApiName)
      .then(function (results) {
        supplierApiService.supplierAToBTransfers = results;
        supplierApiService.filters.AtoBTransfers = results;
        results.forEach(function (result) {
          supplierApiService.companiesSelectorObject[result.company.id] = result.company; // todo, companies with the same id
        })
      })
      .catch(handleError);

      // bToA
      TransferMappingEditService.performTransferSearch(selectedDateInDateFormat, daysInAdvance, toCityId, fromCityId, supplierApiName)
      .then(function (results) {
        supplierApiService.supplierBToATransfers = results;
        supplierApiService.filters.BtoATransfers = results;
        results.forEach(function (result) {
          supplierApiService.companiesSelectorObject[result.company.id] = result.company; // todo, companies with the same id
        });
      })
      .catch(handleError);
    }

    function performLiveResultsSearch(selectedDateInDateFormat) {
      let selectedDate;
      if (selectedDateInDateFormat){
        selectedDate = moment(selectedDateInDateFormat).format("YYYY-MM-DD");
      }
      const fromCityId = _.get(supplierApiService, 'filters.departure._id');
      const toCityId = _.get(supplierApiService, 'filters.arrival._id');
      const supplierApiName = supplierApiService.selectedApiSupplier.name;
      const validationResult = performValidation({fromCityId: fromCityId, toCityId: toCityId});
      if (!validationResult.valid) {
        handleError(validationResult.errors);
        return;
      }

      // init
      supplierApiService.supplierAToBTransfers = [];
      supplierApiService.supplierBToATransfers = [];
      supplierApiService.filters.AtoBTransfers = [];
      supplierApiService.filters.BtoATransfers = [];
      supplierApiService.noResults = false;
      // aToB
      TransferMappingEditService.performLiveResultsSearch(selectedDate, fromCityId, toCityId, supplierApiName)
      .then(function (results) {
        supplierApiService.supplierAToBTransfers = results.trips;
        supplierApiService.filters.AtoBTransfers = results.trips;
      })
      .catch(handleError);

      // bToA
      TransferMappingEditService.performLiveResultsSearch(selectedDateInDateFormat, toCityId, fromCityId, supplierApiName)
      .then(function (results) {
        supplierApiService.supplierBToATransfers = results.trips;
        supplierApiService.filters.BtoATransfers = results.trips;
        supplierApiService.noResults = !(supplierApiService.filters.AtoBTransfers.length || supplierApiService.filters.BtoATransfers.length);
      })
      .catch(handleError);
    }

    function getExistingLinesForRoute(selectedCompanyIdInjected, page = 1) {
      // init
      supplierApiService.routeLines = [];

      const fromCityId = _.get(supplierApiService, 'filters.departure._id');
      const toCityId = _.get(supplierApiService, 'filters.arrival._id');
      const foundCompanyMapping = supplierApiService.companyMapping.find(function (companyMapping) {
        return (companyMapping.supplierData || []).find(function (mapping) {
          const companyId = mapping.companyId;
          const selectedCompanyId = selectedCompanyIdInjected || _.get(supplierApiService, 'filters.selectedSupplierCompany', '');
          return companyId && companyId.toString() === selectedCompanyId.toString();
        });
      });

      const bookawayCompanyId = _.get(foundCompanyMapping, 'bookawayData.companyId');
      const limit = 50;
      const offset = (page - 1) * limit;

      const validationResult = performValidation({fromCityId, toCityId, bookawayCompanyId, page});
      if (!validationResult.valid) {
        handleError(validationResult.errors);
        return;
      }

      InventoryRestangular.all('lines').getList({
        city: fromCityId,
        cityB: toCityId,
        company: bookawayCompanyId,
        limit: limit,
        offset: offset,
        supplierApiMappings: true,
      })
        .then(function (results) {
          supplierApiService.routeLines = results;
          handleSuccess('search bookaway lines complete. found ' + results.length + ' lines');
        }).catch(handleError);
    }

    function getExistingLinesForCompany(page) {
      // init
      supplierApiService.companyLines = [];

      const bookawayCompanyId = _.get(supplierApiService, 'selectedBookawayCompany._id');
      const limit = 100;
      const offset = (page - 1) * limit;

      const validationResult = performValidation({bookawayCompanyId, page});
      if (!validationResult.valid) {
        handleError(validationResult.errors);
        return;
      }

      InventoryRestangular.all('lines').getList({
        company: bookawayCompanyId,
        limit: limit,
        offset: offset,
        supplierApiMappings: true,
      })
        .then(function (results) {
          supplierApiService.companyLines = results;
          handleSuccess('search bookaway lines complete. found ' + results.length + ' lines');
        }).catch(handleError);
    }

    function setTransferMapping(line) {
      const selectedSupplierCompany = _.get(supplierApiService, 'filters.selectedSupplierCompany', '');
      if (!selectedSupplierCompany) {
        return handleError(['must select company first']);
      }
      const supplierApiName = supplierApiService.selectedApiSupplier.name;
      let setTransferMappingPromises = [];
      let atLeastOneTransferSelected = false;

      supplierApiService.filters.AtoBTransfers.forEach(function (transfer) {
        if (transfer.isSelected) {
          transfer.fromCityId = supplierApiService.filters.departure._id;
          transfer.toCityId = supplierApiService.filters.arrival._id;
          atLeastOneTransferSelected = true;
          setTransferMappingPromises.push(TransferMappingEditService.setTransferMapping(line, transfer, supplierApiName));
        }
      });

      supplierApiService.filters.BtoATransfers.forEach(function (transfer) {
        if (transfer.isSelected) {
          transfer.toCityId = supplierApiService.filters.departure._id;
          transfer.fromCityId = supplierApiService.filters.arrival._id;
          atLeastOneTransferSelected = true;
          setTransferMappingPromises.push(TransferMappingEditService.setTransferMapping(line, transfer, supplierApiName));
        }
      });

      // check that only one is selected:
      if (!atLeastOneTransferSelected) {
        handleError(['need to select exactly one mapping']);
        return;
      }

      Promise.all(setTransferMappingPromises)
      .then(function () {
        handleSuccess('set transfer mappings');
        const selectedCompanyIdInjected = _.get(supplierApiService, 'filters.selectedSupplierCompany', '');
        performTransferSearch();
        getExistingLinesForRoute(selectedCompanyIdInjected);
      }).catch(handleError);
    }

    function isEmpty(obj) {
      return typeof(obj) === 'undefined' || Object.keys(obj).length === 0;
    }

    function clearSupplierCompanyFilter() {
      supplierApiService.routeLines = [];
      supplierApiService.filters.selectedSupplierCompany = null;
      supplierApiService.filters.AtoBTransfers = supplierApiService.supplierAToBTransfers;
      supplierApiService.filters.BtoATransfers = supplierApiService.supplierBToATransfers;
    }

    function onSupplierCompanySelected() {
      if (!supplierApiService.filters.selectedSupplierCompany) {
        clearSupplierCompanyFilter();
      } else {
        supplierApiService.routeLines = [];
        supplierApiService.filters.AtoBTransfers = [];
        supplierApiService.supplierAToBTransfers.forEach(function (transfer) {
          transfer.isSelected = false;
          if (transfer.company.id.toString() === supplierApiService.filters.selectedSupplierCompany.toString()) {
            supplierApiService.filters.AtoBTransfers.push(transfer);
          }
        });

        supplierApiService.filters.BtoATransfers = [];
        supplierApiService.supplierBToATransfers.forEach(function (transfer) {
          transfer.isSelected = false;
          if (transfer.company.id.toString() === supplierApiService.filters.selectedSupplierCompany.toString()) {
            supplierApiService.filters.BtoATransfers.push(transfer);
          }
        });

        // Fetch also Bookaway lines
        getExistingLinesForRoute();
      }
    }

    function getSupplierCountries() {
      supplierApiService.supplierCountries = [];
      const supplierApiName = supplierApiService.selectedApiSupplier.name;
      const validationResult = performValidation({
        supplierApiName,
      });
      if (!validationResult.valid) {
        handleError(validationResult.errors);
        return;
      }

      InventoryRestangular.all('v1/supplier-api/' + supplierApiName)
        .get('countries-supplier')
        .then(function (supplierCountries) {
          supplierApiService.supplierCountries = supplierCountries;
          handleSuccess('fetch supplier countries');
        }).catch(handleError);
    }

    function getSupplierCities() {
      supplierApiService.supplierCities = [];
      const supplierApiName = supplierApiService.selectedApiSupplier.name;
      const validationResult = performValidation({
        supplierApiName,
      });
      if (!validationResult.valid) {
        handleError(validationResult.errors);
        return;
      }

      InventoryRestangular.all('v1/supplier-api/' + supplierApiName)
        .get('cities-supplier')
        .then(function (supplierCities) {
          supplierApiService.supplierCities = supplierCities;
          handleSuccess('fetch supplier cities');
        }).catch(handleError);
    }

    function findMatch(line) {
      supplierApiService.setActiveTab(4);
      supplierApiService.filters.departure = line.stationA.city;
      supplierApiService.filters.arrival = line.stationB.city;

      performTransferSearch();

      //   .then(function () {
      //     const foundCompanyMapping = supplierApiService.companyMapping.find(function (companyMapping) {
      //       return companyMapping.bookawayData && companyMapping.bookawayData.companyId && companyMapping.bookawayData.companyId.toString() === line.company._id.toString();
      //     });
      //
      //     if(foundCompanyMapping) {
      //     supplierApiService.filters.selectedSupplierCompany = foundCompanyMapping.supplierData[0];
      //   }
      // });

    }

    function setCityMapping() {
      const supplierApiName = supplierApiService.selectedApiSupplier.name;
      const bookawayCityId = _.get(supplierApiService, 'filters.cityMappingSelectedBookawayCity._id');
      const supplierCityMapping = _.get(supplierApiService, 'filters.cityMappingSelectedSupplierCity.cityMapping');
      const supplierSearchKeys = _.get(supplierApiService, 'filters.cityMappingSelectedSupplierCity.searchKeys');

      const validationResult = performValidation({
        supplierApiName,
        bookawayCityId,
        supplierCityMapping
      });
      if (!validationResult.valid) {
        handleError(validationResult.errors);
        return;
      }

      InventoryRestangular.all('v1/supplier-api/' + supplierApiName + '/cities-mapping')
        .post({cityId: bookawayCityId, cityMappings: [supplierCityMapping], searchKeys: supplierSearchKeys})
        .then(function () {
          handleSuccess('set city mappings');
          refreshCityMappings();
        }).catch(handleError);
    }

    function getSupplierCompanies() {
      supplierApiService.supplierCompanies = [];
      const supplierApiName = supplierApiService.selectedApiSupplier.name;
      const validationResult = performValidation({
        supplierApiName,
      });
      if (!validationResult.valid) {
        handleError(validationResult.errors);
        return;
      }

      InventoryRestangular.all('v1/supplier-api/' + supplierApiName)
        .get('companies-supplier')
        .then(function (supplierCompanies) {
          supplierApiService.supplierCompanies = supplierCompanies;
          handleSuccess('fetch supplier companies');
        }).catch(handleError);
    }

    function setCompanyMapping() {
      const supplierApiName = supplierApiService.selectedApiSupplier.name;
      const bookawayCompanyId = _.get(supplierApiService, 'filters.companyMappingSelectedBookawayCompany._id');
      const supplierCompanyMapping =_.get(supplierApiService, 'filters.companyMappingSelectedSupplierCompany.supplierData[0]') || _.get(supplierApiService, 'filters.companyMappingSelectedSupplierCompany.companyMapping');

      const validationResult = performValidation({
        supplierApiName,
        bookawayCompanyId,
        supplierCompanyMapping
      });
      if (!validationResult.valid) {
        handleError(validationResult.errors);
        return;
      }

      InventoryRestangular.all('v1/supplier-api/' + supplierApiName + '/companies-mapping')
        .post({companyId: bookawayCompanyId, companyMappings: [supplierCompanyMapping]})
        .then(function () {
          handleSuccess('set company mappings');
          refreshCompanyMappings();
        }).catch(handleError);
    }

    function openCreateLineDialog() {
      const supplierApiName = supplierApiService.selectedApiSupplier.name;
      const cityA = _.get(supplierApiService, 'filters.departure');
      const cityB = _.get(supplierApiService, 'filters.arrival');
      const aToBSelectedSupplierTransfers = [];
      const bToASelectedSupplierTransfers = [];
      const selectedSupplierCompany = _.get(supplierApiService, 'filters.selectedSupplierCompany');

      (supplierApiService.filters.AtoBTransfers || []).forEach(function (transfer) {
        if (transfer.isSelected) {
          aToBSelectedSupplierTransfers.push(transfer);
        }
      });

      (supplierApiService.filters.BtoATransfers || []).forEach(function (transfer) {
        if (transfer.isSelected) {
          bToASelectedSupplierTransfers.push(transfer);
        }
      });

      // check that only one is selected:
      if (!((aToBSelectedSupplierTransfers.length === 1 && bToASelectedSupplierTransfers.length === 0) || (aToBSelectedSupplierTransfers.length === 0 && bToASelectedSupplierTransfers.length === 1))) {
        handleError(['need to select exactly one mapping']);
        return;
      }

      let fromCity;
      let toCity;
      let transfer;
      if (aToBSelectedSupplierTransfers.length) {
        transfer = aToBSelectedSupplierTransfers[0];
        fromCity = cityA;
        toCity = cityB;
      } else {
        transfer = bToASelectedSupplierTransfers[0];
        fromCity = cityB;
        toCity = cityA;
      }

      // station id negative is our way to tell that this transfer has no stations
      if(parseInt(transfer.fromStation.id) < 0 || parseInt(transfer.toStation.id) < 0){
        handleError(['creating line automaticly from this line is not allowed']);
        return;
      }

      const validationResult = performValidation({
        supplierApiName,
        transfer,
        fromCity,
        toCity,
        selectedSupplierCompany
      });
      if (!validationResult.valid) {
        handleError(validationResult.errors);
        return;
      }

      $uibModal
        .open({
          templateUrl: 'views/supplier-api/create-line.html',
          controller: 'supplierApiCreateLineCtrl',
          size: 'lg',
          resolve: {
            supplierApiName: function () {
              return supplierApiName;
            },

            transfer: function () {
              return transfer;
            },

            fromCity: function () {
              return fromCity;
            },
            toCity: function () {
              return toCity;
            },

            fromStationWithMapping: ['InventoryRestangular', function (InventoryRestangular) {
              return InventoryRestangular.all('v1/supplier-api/' + supplierApiName)
                .get('station-mapping/by-supplier-mapping/' + transfer.fromStation.id);
            }],
            toStationWithMapping: ['InventoryRestangular', function (InventoryRestangular) {
              return InventoryRestangular.all('v1/supplier-api/' + supplierApiName)
                .get('station-mapping/by-supplier-mapping/' + transfer.toStation.id);
            }],
            companyWithMapping: ['InventoryRestangular', function (InventoryRestangular) {
              return InventoryRestangular.all('v1/supplier-api/' + supplierApiName)
                .get('company-mapping/by-supplier-mapping/' + selectedSupplierCompany);
            }],
            vehicleClassWithMapping: ['InventoryRestangular', function (InventoryRestangular) {
              if (supplierApiName === '12go') {
                return InventoryRestangular.one('v1/supplier-api/' + supplierApiName + '/vehicle-classes-mapping').get({vehicleClassName: transfer.vehicleClass})
              }}],
            vehicleTypeWithMapping: ['InventoryRestangular', function (InventoryRestangular) {
              if (supplierApiName === '12go') {
                return InventoryRestangular.one('v1/supplier-api/' + supplierApiName + '/vehicle-types-mapping').get({vehicleTypeName: transfer.vehicleType})
              }
            }],
           }
         })
        .result
        .then(function (result) {
          if (result.status === 'success') {
            handleSuccess('line created with success');
            performTransferSearch();
            getExistingLinesForRoute(selectedSupplierCompany);
          } else if (result.status === 'error') {
            if (result.err) {
              handleError(result.err);
            } else {
              handleError(['operation failed']);
            }
          } else {
            handleError(['unknown']);
          }
        }).catch(handleError);
    }

    function getMapLinkForAddress(query) {
      const encodedQuery = encodeURIComponent(query);
      const result = 'https://www.google.com/maps/search/' + encodedQuery;
      return result;
    }

    function getStationLocLink(locArray = []) {
      return getMapLinkForAddress((locArray[1] || '') + ',' + (locArray[0] || ''));
    }

    function deleteOneCityMapping(cityId) {
      const supplierApiName = supplierApiService.selectedApiSupplier.name;
      InventoryRestangular.one('v1/supplier-api/' + supplierApiName + '/cities-mapping/' + cityId).remove()
        .then(function () {
          handleSuccess('delete mapping for city: ' + cityId + ' success');
          refreshCityMappings();
        }).catch(handleError);
    }

    function deleteOneCompanyMapping(companyId) {
      const supplierApiName = supplierApiService.selectedApiSupplier.name;
      InventoryRestangular.one('v1/supplier-api/' + supplierApiName + '/companies-mapping/' + companyId).remove()
        .then(function () {
          handleSuccess('delete mapping for company: ' + companyId + ' success');
          refreshCompanyMappings();
        }).catch(handleError);
    }

    function deleteOneVehicleTypeMapping(vehicleTypeId) {
      const supplierApiName = supplierApiService.selectedApiSupplier.name;
      InventoryRestangular.one('v1/supplier-api/' + supplierApiName + '/vehicle-types-mapping/' + vehicleTypeId).remove()
        .then(function () {
          handleSuccess('delete mapping for vehicle type: ' + vehicleTypeId + ' success');
          refreshVehicleTypeMappings();
        }).catch(handleError);
    }

    function deleteOneVehicleClassMapping(vehicleClassId) {
      const supplierApiName = supplierApiService.selectedApiSupplier.name;
      InventoryRestangular.one('v1/supplier-api/' + supplierApiName + '/vehicle-classes-mapping/' + vehicleClassId).remove()
        .then(function () {
          handleSuccess('delete mapping for vehicle class: ' + vehicleClassId + ' success');
          refreshVehicleClassMappings();
        }).catch(handleError);
    }

    function performBulkMapping(mappings, mappingReport) {
      const supplierApiName = supplierApiService.selectedApiSupplier.name;
      return InventoryRestangular.all('v1/supplier-api/' + supplierApiName + '/validate-mappings/')
      .post({
        mappings
      })
      .then((response) => {
        const report = mappingReport.map((line, i) =>
        `${++i}. ${line.supplierCompanyName} - ${line.supplierFromCityName} - ${line.supplierToCityName} - ${line.vehicleType}`).join('\r\n');
        return $uibModal
        .open({
          templateUrl: 'views/are-you-sure-modal.html',
          controller: 'AreYouSureCtrl',
          resolve: {
            text: [function () { return report; }],
            title: [function () { return 'Create & Map'; }]
          }
        })
        .result
        .then(function (response) {
          if (response) {
            return InventoryRestangular.all('v1/supplier-api/' + supplierApiName + '/bulk-mapping/')
              .post({
                mappings,
                countries: supplierApiService.lastSearchedCountries
              })
              .then(function (data) {
                return data;
              }).catch(handleError);
          }
        });
      })
      .catch(handleError)
    }

    function getSupplierVehicleTypes() {
      supplierApiService.supplierVehicleTypes = [];
      const supplierApiName = supplierApiService.selectedApiSupplier.name;
      const validationResult = performValidation({
        supplierApiName,
      });
      if (!validationResult.valid) {
        handleError(validationResult.errors);
        return;
      }

      InventoryRestangular.all('v1/supplier-api/' + supplierApiName)
        .get('vehicle-types-supplier')
        .then(function (supplierVehicleTypes) {
          supplierApiService.supplierVehicleTypes = supplierVehicleTypes;
          handleSuccess('fetch supplier vehicle types');
        }).catch(handleError);
    }

    function setVehicleTypeMapping() {
      const supplierApiName = supplierApiService.selectedApiSupplier.name;
      const bookawayVehicleTypeId = _.get(supplierApiService, 'filters.vehicleTypeMappingSelectedBookawayVehicleType');
      const supplierVehicleTypeMapping = _.get(supplierApiService, 'filters.vehicleTypeMappingSelectedSupplierVehicleType.vehicleTypeMapping');

      const validationResult = performValidation({
        supplierApiName,
        bookawayVehicleTypeId,
        supplierVehicleTypeMapping
      });
      if (!validationResult.valid) {
        handleError(validationResult.errors);
        return;
      }

      InventoryRestangular.all('v1/supplier-api/' + supplierApiName + '/vehicle-types-mapping')
        .post({vehicleTypeId: bookawayVehicleTypeId, vehicleTypeMappings: [supplierVehicleTypeMapping]})
        .then(function () {
          handleSuccess('set vehicle type mappings');
          refreshVehicleTypeMappings();
        }).catch(handleError);
    }

    function getSupplierVehicleClasses() {
      supplierApiService.supplierVehicleClasses = [];
      const supplierApiName = supplierApiService.selectedApiSupplier.name;
      const validationResult = performValidation({
        supplierApiName,
      });
      if (!validationResult.valid) {
        handleError(validationResult.errors);
        return;
      }

      InventoryRestangular.all('v1/supplier-api/' + supplierApiName)
        .get('vehicle-classes-supplier')
        .then(function (supplierVehicleClasses) {
          supplierApiService.supplierVehicleClasses = supplierVehicleClasses;
          handleSuccess('fetch supplier vehicle classes');
        }).catch(handleError);
    }

    function setVehicleClassMapping() {
      const supplierApiName = supplierApiService.selectedApiSupplier.name;
      const bookawayVehicleClassId = _.get(supplierApiService, 'filters.vehicleClassMappingSelectedBookawayVehicleClass');
      const supplierVehicleClassMapping = _.get(supplierApiService, 'filters.vehicleClassMappingSelectedSupplierVehicleClass.vehicleClassMapping');

      const validationResult = performValidation({
        supplierApiName,
        bookawayVehicleClassId,
        supplierVehicleClassMapping
      });
      if (!validationResult.valid) {
        handleError(validationResult.errors);
        return;
      }

      InventoryRestangular.all('v1/supplier-api/' + supplierApiName + '/vehicle-classes-mapping')
        .post({vehicleClassId: bookawayVehicleClassId, vehicleClassMappings: [supplierVehicleClassMapping]})
        .then(function () {
          handleSuccess('set vehicle class mappings');
          refreshVehicleClassMappings();
        }).catch(handleError);
    }

    function getInventory() {
      supplierApiService.operatorsBulk = [];
      const supplierApiName = supplierApiService.selectedApiSupplier.name;
      const validationResult = performValidation({
        supplierApiName,
      });
      if (!validationResult.valid) {
        handleError(validationResult.errors);
        return;
      }

      InventoryRestangular.all('v1/supplier-api/' + supplierApiName)
        .get('companies-supplier-bulk')
        .then(function (supplierCompaniesBulk) {
          supplierApiService.operatorsBulk = supplierCompaniesBulk;
          handleSuccess('fetch supplier companies');
        }).catch(handleError);
    }

    function changeOperator() {
      const filters = {
        filterByDepartureCity: '',
        filterByArrivalCity: '',
        filterByDepartureCountryName: '',
        filterByVehicle: '',
        filterByMappings: '',
        limit: 50,
        offset: 0,
      };
      supplierApiService.transfers = [];
      if (!supplierApiService.selectedOperator) {
        return;
      }
      const supplierApiName = supplierApiService.selectedApiSupplier.name;
      return InventoryRestangular.one('v1/supplier-api/' + supplierApiName)
      .all('get-inventory/' + supplierApiService.selectedOperator.id).getList({ filters })
      .then((response) => {
        supplierApiService.transfers = response;
        supplierApiService.currentPage = 1;
      })
      .catch(handleError);
    }

    function fetchInventoryWithPagination(filters) {
      supplierApiService.transfers = [];
      const supplierApiName = supplierApiService.selectedApiSupplier.name;
      return InventoryRestangular.one('v1/supplier-api/' + supplierApiName)
      .all('get-inventory/' + supplierApiService.selectedOperator.id).getList({ filters })
      .then((response) => {
        supplierApiService.transfers = response;
        supplierApiService.currentPage = filters.currentPage;
        supplierApiService.totalTransfers = response.meta.total;
        return response;
      })
      .catch(handleError);
    }

    function filterVehicleClasses() {
      var termToCheckDeparture = new RegExp(supplierApiService.filterByVehicleClass || '', 'i');
      supplierApiService.filteredVehicleClasses = supplierApiService.vehicleClassMapping.filter(function (vehicleClass) {
        var vehicleClassName = _.get(vehicleClass, 'supplierData[0].vehicleClassName', '') || '';
        return (
          !supplierApiService.filterByVehicleClass ||
          StringHelperService.removeDiacritics(vehicleClassName.toString()).match(termToCheckDeparture)
        )
      });
    };

    function isSupplierSupportsBulkMapping() {
      return ['12go', 'distribusion'].includes(supplierApiService.selectedApiSupplier.name);
    }

    supplierApiService.updateDataSupplierChange = updateDataSupplierChange;
    supplierApiService.updateDataOverviewOperatorChange = updateDataOverviewOperatorChange;
    supplierApiService.performTransferSearch = performTransferSearch;
    supplierApiService.performLiveResultsSearch = performLiveResultsSearch;
    supplierApiService.getExistingLinesForRoute = getExistingLinesForRoute;
    supplierApiService.getExistingLinesForCompany = getExistingLinesForCompany;
    supplierApiService.setTransferMapping = setTransferMapping;
    supplierApiService.isEmpty = isEmpty;
    supplierApiService.onSupplierCompanySelected = onSupplierCompanySelected;
    supplierApiService.clearSupplierCompanyFilter = clearSupplierCompanyFilter;
    supplierApiService.findMatch = findMatch;
    supplierApiService.getSupplierCities = getSupplierCities;
    supplierApiService.setCityMapping = setCityMapping;
    supplierApiService.getSupplierCompanies = getSupplierCompanies;
    supplierApiService.setCompanyMapping = setCompanyMapping;
    supplierApiService.openCreateLineDialog = openCreateLineDialog;
    supplierApiService.getMapLinkForAddress = getMapLinkForAddress;
    supplierApiService.getStationLocLink = getStationLocLink;
    supplierApiService.deleteOneCityMapping = deleteOneCityMapping;
    supplierApiService.deleteOneCompanyMapping = deleteOneCompanyMapping;
    supplierApiService.deleteOneVehicleTypeMapping = deleteOneVehicleTypeMapping;
    supplierApiService.deleteOneVehicleClassMapping = deleteOneVehicleClassMapping;
    supplierApiService.getSupplierCountries = getSupplierCountries;
    supplierApiService.performCountryToCountryTransferSearch = performCountryToCountryTransferSearch;
    supplierApiService.performCountryToCountryTransferSearchFromRemote = performCountryToCountryTransferSearchFromRemote;
    supplierApiService.performBulkMapping = performBulkMapping;
    supplierApiService.getSupplierVehicleTypes = getSupplierVehicleTypes;
    supplierApiService.setVehicleTypeMapping = setVehicleTypeMapping;
    supplierApiService.getSupplierVehicleClasses = getSupplierVehicleClasses;
    supplierApiService.setVehicleClassMapping = setVehicleClassMapping;
    supplierApiService.getInventory = getInventory;
    supplierApiService.changeOperator = changeOperator;
    supplierApiService.filterVehicleClasses = filterVehicleClasses;
    supplierApiService.fetchInventoryWithPagination = fetchInventoryWithPagination;
    supplierApiService.getCompanyMappings = getCompanyMappings;
    supplierApiService.isSupplierSupportsBulkMapping = isSupplierSupportsBulkMapping;

    return supplierApiService;
  }];

}

'use strict';

/* exported
 rideRulesProvider
 */

function rideRulesProvider() {
    this.$get = ['$sce', 'appConfig', 'SearchRestangular', 'InventoryRestangular', function ($sce, appConfig, SearchRestangular, InventoryRestangular) {
        var rideRulesService = {
            attributeValidationTypes: {
                TEXT: 'TEXT',
                NUMERIC: 'NUMERIC',
                PERCENTAGE: 'PERCENTAGE',
                BOOLEAN: 'BOOLEAN',
                OBJECT: 'OBJECT',
                LIST: 'LIST',
                OPTIONS: 'OPTIONS',
                CUSTOM: 'CUSTOM',
            },
            hardcodedAttributes: [],
            ATTRIBUTE_EDIT_SOURCES: {
                LINE: 'line',
                RULE: 'rule',
            }
        }

        var amenityCommentsOptions;
        SearchRestangular.one('amenities', 'en').get().then((res) => {
            amenityCommentsOptions = res
        });

        function validateBoolean(value, validation) {
            if (value === null || value === undefined) {
                return !validation.required;
            }
            if (value === true || value === false) {
                return true;
            }
            return false;
        }
        function validateText(value, validation) {
            if (value === null || value === undefined) {
                return !validation.required;
            }
            if (typeof (value) !== 'string') {
                return false;
            }
            if (validation.min && value.length < validation.min) {
                return false;
            }
            if (validation.max && value.length > validation.max) {
                return false;
            }
            if (validation.regex && !(new RegExp(validation.regex).test(value))) {
                return false;
            }
            return true;
        }
        function validateNumeric(value, validation) {
            if (value === null || value === undefined) {
                return !validation.required;
            }
            if (typeof (value) !== 'number') {
                return false;
            }
            if ((validation.min || validation.min === 0) && value < validation.min) {
                return false;
            }
            if (validation.max && value > validation.max) {
                return false;
            }
            return true;
        }
        function validateObject(object, validation) {
            if (object === null || object === undefined) {
                return !validation.required;
            }
            if (typeof (value) !== 'object') {
                return false;
            }
            let isValid = true;
            if (validation.fieldsValidation) {
                validation.fieldsValidation.forEach(field => {
                    const fieldValue = object[field.fieldsValidationName];
                    const isFieldValid = validateRideRuleAttribute(fieldValue, field);
                    if (!isFieldValid) {
                        isValid = isFieldValid;
                    }
                });
            }
            return isValid;
        }
        function validateList(list, validation) {
            if (list === null || list === undefined || list.length === 0) {
                return !validation.required;
            }
            if (!Array.isArray(list)) {
                return false;
            }
            let isValid = true;
            if (validation.itemValidation) {
                list.forEach(item => {
                    const isItemValid = validateRideRuleAttribute(item, validation.itemValidation);
                    if (!isItemValid) {
                        isValid = isItemValid;
                    }
                });
            }
            return isValid;
        }
        function validateOptions(option, validation) {
            if (option === null || option === undefined) {
                return !validation.required;
            }
            let isValid = true;
            if (validation.options) {
                const isItemValid = !!validation.options.find(validOption => option === validOption);
                if (!isItemValid) {
                    isValid = isItemValid;
                }
            }
            return isValid;
        }

        function validateCustom(value, validation) {
            return true;
        }
        function validateRideRuleAttribute(value, validation) {
          switch (validation.type) {
              case rideRulesService.attributeValidationTypes.BOOLEAN:
                  return validateBoolean(value, validation);
              case rideRulesService.attributeValidationTypes.TEXT:
                  return validateText(value, validation);
              case rideRulesService.attributeValidationTypes.NUMERIC:
              case rideRulesService.attributeValidationTypes.PERCENTAGE:
                  return validateNumeric(value, validation);
              case rideRulesService.attributeValidationTypes.OBJECT:
                  return validateObject(value, validation);
              case rideRulesService.attributeValidationTypes.LIST:
                  return validateList(value, validation);
              case rideRulesService.attributeValidationTypes.OPTIONS:
                  return validateOptions(value, validation);
              case rideRulesService.attributeValidationTypes.CUSTOM:
                  return validateCustom(value, validation);
              default:
                  return false;
          }
        }

        function createLuggageFormatter() {
            const connectionWords = {
                handbag: "and",
                skiAndSnowboard: "plus",
            };
            const luggageRules1stPattern = {
                largeBag: [
                    {
                        condition: (value) => value.amount && !value.weight,
                        template: (value) => `${value.amount} bag`,
                    },
                    {
                        condition: (value) => value.amount === 1 && value.weight,
                        template: (value) => `${value.weight} kg bag`,
                    },
                    {
                        condition: (value) => value.amount > 1 && value.weight !== null,
                        template: (value) => `${value.amount} x ${value.weight} kg bags`,
                    },
                    {
                        condition: (value) => value.measureUnit === "cm" && value.length && value.width && value.height,
                        template: (value) => `(${value.length} cm x ${value.width} cm x ${value.height} cm)`,
                    },
                    {
                        condition: (value) => value.measureUnit === "cm" && value.length && (!value.width || !value.height),
                        template: (value) => `${value.length} cm`,
                    },
                    {
                        condition: (value) => value.measureUnit === "inch" && value.length !== null,
                        template: (value) => `(${value.width} inch)`,
                    },
                ],
                handbag: [
                    {
                        condition: (value) => value.amount && !value.weight,
                        template: (value) => `${value.amount} bag`,
                    },
                    {
                        condition: (value) => value.amount === 1 && value.weight,
                        template: (value) => `${value.weight} kg handbag`,
                    },
                    {
                        condition: (value) => value.amount > 1 && value.weight !== null,
                        template: (value) => `${value.amount} x ${value.weight} kg handbags`,
                    },
                    {
                        condition: (value) => value.measureUnit === "cm" && value.length && value.width && value.height,
                        template: (value) => `(${value.length} cm x ${value.width} cm x ${value.height} cm)`,
                    },
                    {
                        condition: (value) => value.measureUnit === "cm" && value.length && (!value.width || !value.height),
                        template: (value) => `${value.length} cm`,
                    },
                    {
                        condition: (value) => value.measureUnit === "inch" && value.length !== null,
                        template: (value) => `(${value.length} inch)`,
                    },
                ],
                skiAndSnowboard: [
                    {
                        condition: (value) => value.amount && !value.weight,
                        template: (value) => `${value.amount} Ski/snowboard bag`,
                    },
                    {
                        condition: (value) => value.amount === 1 && value.weight,
                        template: (value) => `${value.weight} kg Ski/snowboard bag`,
                    },
                    {
                        condition: (value) => value.amount > 1 && value.weight,
                        template: (value) => `${value.amount}} x ${value.weight} kg Ski/snowboard bags`,
                    },
                ],
                passengerOrVehicle: [
                    {
                        condition: (value) => !!value,
                        template: (value) => `${value === "passenger" ? "per passenger" : "per vehicle"}`,
                    },
                ],
                totalWeight: [
                    {
                        condition: (value) => !!value,
                        template: (value) => `Up to ${value} kg`,
                    },
                ],
            };

            const luggageRules2ndPattern = {
                totalWeight: [
                    {
                        condition: (value) => !!value,
                        template: (value) => `Up to ${value} kg`,
                    },
                ],
                passengerOrVehicle: [
                    {
                        condition: (value) => !!value,
                        template: (value) => `${value === "passenger" ? "per passenger" : "per vehicle"}`,
                    },
                ],
            };

            const applyRule = (condition, template, value) => {
                if (condition(value)) {
                    return template(value);
                }
                return "";
            };

            const generateDescription = (rules, value) => {
                let description = "";
                rules.forEach((rule) => {
                    const { condition, template } = rule;
                    const result = applyRule(condition, template, value);
                    description += result + " ";
                });

                return description;
            };

            const decideRuleSet = (luggage) => {
                const useFirstPattern = Object.keys(luggage).some((key) => {
                    return key !== "passengerOrVehicle" && key !== "totalWeight" && luggage[key];
                });
                return useFirstPattern ? luggageRules1stPattern : luggageRules2ndPattern;
            };

            return function (luggage) {
                if (!Object.keys(luggage).length) {
                    return;
                }
                if (luggage.openText) {
                    return luggage.openText;
                }
                if (luggage.customLuggage) {
                    let description = "";
                    const selectedPattern = decideRuleSet(luggage.customLuggage);
                    const rulesKeys = Object.keys(selectedPattern);
                    rulesKeys.forEach((key) => {
                        if (!luggage.customLuggage[key]) {
                            return "";
                        }
                        const keyDesc = generateDescription(selectedPattern[key], luggage.customLuggage[key]);
                        if (description && keyDesc && connectionWords[key]) {
                            return (description += `${connectionWords[key]} ${keyDesc}`);
                        }
                        return (description += generateDescription(selectedPattern[key], luggage.customLuggage[key]));
                    });
                    return description;
                }
            };
        }

        const formatLuggage = createLuggageFormatter();

        function getThumbnailStyle(image) {
            var thumbnailUrl = appConfig.imagesServiceBaseUrl + "/media/files/" + image.url + "?width=300";
            return `background-image: url(&quot;${thumbnailUrl}&quot;);`;
        }

        function formatCancellations(value) {
            if (!value.cancellations.length) {
                return "Not refundable" + $sce.trustAsHtml(value.isFlexibleCancellation ? '<br>Flexible cancellation' : '');
            }
            return value.cancellations
                .map((policy) => {
                    if(policy.timeBefore && policy.unit) {
                        let timeBeforeStr = `${policy.timeBefore} ${policy.unit}`;
                        if (policy.timeBefore > 1) {
                            timeBeforeStr += "s";
                        }
                        return `Refundable order date until ${timeBeforeStr} before departure ${policy.fee}%`;
                    } else {
                        return undefined;
                    }
                })
                .join("<br>");
        }

        function formatAmendments(amendments) {
            if (!amendments.length) {
                return "Not amendable";
            }
            return amendments
                .map((policy) => {
                    let timeBeforeStr = `${policy.timeBefore} ${policy.unit}`;
                    if (policy.timeBefore > 1) {
                        timeBeforeStr += "s";
                    }
                    return `Amendable order date until ${timeBeforeStr} before departure ${policy.fee}%`;
                })
                .join("<br>");
        }

        function getAmenityComment(amenity) {
            const options = amenity.label && amenityCommentsOptions[amenity.label];
            const option = options && options.find(item => item.key === amenity.comment);
            return (option && option.text) || amenity.comment;
        }

        function formatAmenitiesAndFacilities(amenities) {
            if (!amenities.length) {
                return;
            }

            return $sce.trustAsHtml(
                amenities
                    .map((amenity) =>
                        [
                            `<div style="max-width: 320px; white-space: nowrap; display: flex; align-items: center; line-height: 24px">`,
                            `<i class="${amenity.icon.provider} ${amenity.icon.cssClass}" style="color: #8E8E93"></i>`,
                            `<span>${amenity.label}</span>`,
                            amenity.comment && `<span style="color: #8E8E93; margin-left: 10px; font-size: 12px;overflow: hidden; text-overflow: ellipsis;">${getAmenityComment(amenity)}</span>`,
                            "</div>",
                        ]
                            .filter((value) => value)
                            .join("")
                    )
                    .join("")
            );
        }

        function formatPictures(pictures) {
            if (!pictures.length) {
                return;
            }
            return $sce.trustAsHtml(
                [
                    '<div style="display: flex;">',
                    ...pictures.flatMap((picture) => [
                        `<div style="padding:4px 8px;" class="image-thumbnail-container">`,
                        `<div style="width: 75px; height: 75px; ${getThumbnailStyle(picture)}" class="image-thumbnail"></div>`,
                        "</div>",
                    ]),
                    "</div>",
                ].join("")
            );
        }

        function formatVoucherSettings(voucherSettings) {
            return [
                voucherSettings.isPrintedVoucherRequire && "Require Printed Voucher",
                voucherSettings.showPriceOnVoucher && "Show Price On Voucher",
                voucherSettings.showCostOnVoucher && "Show Cost On Voucher",
                voucherSettings.showPriceInSupplierCurrencyOnVoucher && "Show Price In Supplier Currency On Voucher",
                voucherSettings.showCostInSupplierCurrencyOnVoucher && "Show  Cost In Supplier Currency On Voucher",
                voucherSettings.showNumberOfTicketsOnVoucher && "Show number of tickets on Voucher",
                voucherSettings.isDisplayLogoAsSupplier && "Display logo as supplier",
                voucherSettings.remark && `Remark: ${voucherSettings.remark}`,
                voucherSettings.title && `Title: ${voucherSettings.title}`,
                voucherSettings.text && `Text: ${voucherSettings.text}`,
            ].filter((value) => !!value).join("<br>");
        }

        const formatPercentage = value => `${value}%`;
        const trustAsHtml = value => $sce.trustAsHtml(value);

        const formatedRulesByAttributeSlug = {
            "booking-fee": (bookingFee, supplierCurrency) => `${bookingFee.amount} ${supplierCurrency}${bookingFee.isPerPassenger ? " per passenger" : ""}`,
            "cancellation-policy": formatCancellations,
            markup: formatPercentage,
            "commission-from-api": formatPercentage,
            "return-discount": formatPercentage,
            "return-cost-discount": formatPercentage,
            "commission": formatPercentage,
            "amendment-policy": formatAmendments,
            "custom-services": formatCustomServices,
            "amenities-and-facilities": formatAmenitiesAndFacilities,
            pictures: formatPictures,
            "voucher-settings": formatVoucherSettings,
            "voucher-alert": formatVoucherSettings,
            luggage: formatLuggage,
            "special-notes": trustAsHtml,
            "pickup-dropoff-points": trustAsHtml,
            "seat-type": trustAsHtml,
        };

        function formatCustomServices(customServices) {
            if (!customServices) {
                return;
            }
            return $sce.trustAsHtml(`<div>${customServices.serviceType}: ${customServices.serviceContent}</div>`);
        }

        //TODO: move the function refreshRules from transport-edit-provider to here
        function getRules(supplierApiService, callback) {
            const supplierId = supplierApiService.selectedApiSupplier.supplierId;
            const operator =  supplierApiService.rulesAttributeFilters.operator;
            const url = operator ?
              `/ride-rules/operator/${operator.bookawayData.companyId}?supplierId=${supplierId}` :
              `/ride-rules/supplier/${supplierId}`;

            InventoryRestangular.all(url)
              .getList()
              .then(function (rules) {
                supplierApiService.rulesAttribute = sortRulesAttribute(rules);
                supplierApiService.ruleCategories = getRuleCategories(supplierApiService.rulesAttribute);
                supplierApiService.rulesAttributeFilters.category = supplierApiService.ruleCategories[0];

                if(callback && typeof callback === "function") {
                  callback(rules);
                }
              });
        }

        function rulesFormatHandler(attribueSlug, ruleValue, supplierCurrency) {
            if (!ruleValue) {
                return;
            }
            const formatFunction = formatedRulesByAttributeSlug[attribueSlug];
            if (!!formatFunction) {
                return formatFunction(ruleValue, supplierCurrency);
            }
            return ruleValue;
        }

        const defaultRuleCategory = 'All rules';

        function getRuleCategories(rulesAttribute = []) {
            return [...new Set([defaultRuleCategory].concat(rulesAttribute.map(ruleAttribute => ruleAttribute.category)))];
        }

        function conditionsFormatHandler(rule, ruleAttribute) {
            if (!rule || !rule.attributes || !Array.isArray(rule.attributes) || rule.attributes.length === 0 || rule.attributes[0].value === null) {
                return ruleAttribute.overridingRules > 0 ? 'Not set for this level' : null;
            }

            if (rule.source === RIDE_RULE_LEVELS.SUPPLIER) {
                if(rule && rule.conditions && rule.conditions.operator && rule.conditions.operator.length > 0) {
                    return 'Operators: ' + rule.conditions.operator.map(_ => _.name).join(', ');
                }
                return 'Operators: All';
            }

            const formatCondition = (condition) => condition && condition.length ? condition.join(', ') : 'All';

            const formatRouteCondition = (condition) => {
              if (condition && condition.length){
                return condition.map(item => `${_.get(item, 'fromCityId.name')} to ${_.get(item, 'toCityId.name')}`).join(', ');
              } else {
                return 'All';
              }
            };

            return $sce.trustAsHtml([
                '<div style="display: grid; grid-template-columns: 110px auto;">',
                '<div>Vehicle types: </div>',
                `<div>${formatCondition(rule.conditions.vehicleType)}</div>`,
                '<div>Vehicle classes: </div>',
                `<div>${formatCondition(rule.conditions.vehicleClass)}</div>`,
                '<div>Routes: </div>',
                `<div>${formatRouteCondition(rule.conditions.routes)}</div>`,
                '</div>',
            ].join(''));
        }

        // sorts alphabetically by display name
        function sortRulesAttribute(rulesAttribute) {
            const copy = Array.from(rulesAttribute);
            copy.sort((ruleAttributeA, ruleAttributeB) => ruleAttributeA.displayName > ruleAttributeB.displayName ? 1 : -1 )
            return copy;
        }

        function isAttributeApiForSupplier(ruleAttribute, supplierCompanyId) {
            return !ruleAttribute.rules.length && ruleAttribute.isApiSourced && ruleAttribute.isApiSourced.includes(supplierCompanyId);
        }

        rideRulesService.validateRideRuleAttribute = validateRideRuleAttribute;
        rideRulesService.rulesFormatHandler = rulesFormatHandler;
        rideRulesService.getRuleCategories = getRuleCategories;
        rideRulesService.defaultRuleCategory = defaultRuleCategory;
        rideRulesService.conditionsFormatHandler = conditionsFormatHandler;
        rideRulesService.sortRulesAttribute = sortRulesAttribute;
        rideRulesService.isAttributeApiForSupplier = isAttributeApiForSupplier;
        rideRulesService.getRules = getRules;

        return rideRulesService;
    }]
}

'use strict';

/*
exported
transferMappingDirective
 */

function transferMappingDirective(StringHelperService, SupplierApiService) {
  return {
    restrict: 'AE',
    // require: 'ngModel',
    templateUrl: 'views/supplier-api/transfer-mapping-table.directive.html',
    scope: {
      transfers: '=transfers',
      bookawaylines: '@',
    },
    link: function (scope, iElement, iAttrs, ngModelCtrl) {
      scope.filterByDepartureStation = '';
      scope.filterByArrivalStation = '';
      scope.filterByVehicleType = '';
      scope.filterByVehicleClass = '';

      function setPagingData(page) {
        var pagedData = scope.filteredData.slice(
          (page - 1) * scope.pagination.itemsPerPage,
          page * scope.pagination.itemsPerPage
        );
        scope.visibleTransfers = pagedData;
        scope.pagination.currentPage = page;
      }

      scope.filterData = function () {
        var termToCheckDeparture = new RegExp(scope.filterByDepartureStation || '', 'i');
        var termToCheckArrival = new RegExp(scope.filterByArrivalStation || '', 'i');
        var termToCheckVehicleType = new RegExp(scope.filterByVehicleType || '', 'i');
        var termToCheckVehicleClass = new RegExp(scope.filterByVehicleClass || '', 'i');
        const departureStationName = scope.bookawaylines ? 'stationA.name' : 'fromStation.name';
        const arrivalStationsName = scope.bookawaylines ? 'stationB.name' : 'toStation.name';
        const departureStationAddress = scope.bookawaylines ? 'stationA.address' : 'fromStation.address';
        const arrivalStationAddress = scope.bookawaylines ? 'stationB.address' : 'toStation.address';
        const departureStationId = scope.bookawaylines ? 'stationA._id' : 'fromStation.id';
        const arrivalStationId = scope.bookawaylines ? 'stationB._id' : 'toStation.id';
        scope.filteredData = scope.transfers.filter(function (transfer) {
          return (
              !scope.filterByDepartureStation ||
              StringHelperService.removeDiacritics(_.get(transfer, departureStationName, '').toString()).match(termToCheckDeparture) ||
              StringHelperService.removeDiacritics(_.get(transfer, departureStationId, '').toString()).match(termToCheckDeparture) ||
              StringHelperService.removeDiacritics(_.get(transfer, departureStationAddress, '').toString()).match(termToCheckDeparture)
            ) &&
            (
              !scope.filterByArrivalStation ||
              StringHelperService.removeDiacritics(_.get(transfer, arrivalStationsName, '').toString()).match(termToCheckArrival) ||
              StringHelperService.removeDiacritics(_.get(transfer, arrivalStationId, '').toString()).match(termToCheckArrival) ||
              StringHelperService.removeDiacritics(_.get(transfer, arrivalStationAddress, '').toString()).match(termToCheckArrival)
            ) &&
            (
              !scope.filterByVehicleType ||
              StringHelperService.removeDiacritics(_.get(transfer, 'vehicleType', '').toString()).match(termToCheckVehicleType)
            ) &&
            (
                !scope.filterByVehicleClass ||
                StringHelperService.removeDiacritics(_.get(transfer, 'vehicleClass', '').toString()).match(termToCheckVehicleClass)
            );
        });
      };

      scope.setTransferMapping = function (line) {
        SupplierApiService.setTransferMapping(line);
      };

      scope.refresh = function () {
        scope.totalSelected = scope.transfers.filter(function (transfer) {
          return transfer.isSelected;
        }).length;
        scope.filterData();
        scope.pagination = {
          currentPage: 1,
          itemsPerPage: 8,
          totalItems: scope.filteredData.length
        };
        setPagingData(1);
      };

      scope.$watch('transfers', function () {
        scope.refresh();
      }, true);

      scope.$watch('filterByDepartureStation + filterByArrivalStation + filterByVehicleType + filterByVehicleClass', function () {
        scope.refresh();
      }, true);

      scope.$watch('currentPage', function () {
        setPagingData(scope.pagination.currentPage);
      });

      scope.pageChanged = function () {
        setPagingData(scope.pagination.currentPage);
      };

      scope.getMapLinkForAddress = function (query) {
        const encodedQuery = encodeURIComponent(query);
        const result = 'https://www.google.com/maps/search/' + encodedQuery;
        return result;
      };

      scope.clearSelected = function () {
        scope.transfers.forEach(function (transfer) {
          transfer.isSelected = false;
        });
      };

      scope.clearFilter = function () {
        scope.filterByDepartureStation = '';
        scope.filterByArrivalStation = '';
        scope.filterByVehicleType = '';
        scope.filterByVehicleClass = '';
      };
    }
  };
}

'use strict';

/*
exported
liveResultsMappingDirective
 */

function liveResultsMappingDirective(StringHelperService, SupplierApiService) {
  return {
    restrict: 'AE',
    // require: 'ngModel',
    templateUrl: 'views/supplier-api/live-results-mapping-table.directive.html',
    scope: {
      transfers: '=transfers',
    },
    link: function (scope, iElement, iAttrs, ngModelCtrl) {
      scope.filterByOperator = '';
      scope.filterByDepartureStation = '';
      scope.filterByArrivalStation = '';
      scope.filterByVehicleType = '';
      scope.filterByVehicleClass = '';
      scope.showUnmappedParams = false;

      function setPagingData(page) {
        var pagedData = scope.filteredData.slice(
          (page - 1) * scope.pagination.itemsPerPage,
          page * scope.pagination.itemsPerPage
        );
        scope.visibleTransfers = pagedData;
        scope.pagination.currentPage = page;
      }

      scope.filterData = function () {
        var termToCheckOperator = new RegExp(scope.filterByOperator || '', 'i');
        var termToCheckDeparture = new RegExp(scope.filterByDepartureStation || '', 'i');
        var termToCheckArrival = new RegExp(scope.filterByArrivalStation || '', 'i');
        var termToCheckVehicleType = new RegExp(scope.filterByVehicleType || '', 'i');
        var termToCheckVehicleClass = new RegExp(scope.filterByVehicleClass || '', 'i');
        scope.filteredData = scope.transfers.filter(function (transfer) {
          const isUnmapped = transfer.unmappedParams ? transfer.unmappedParams.length > 0 : false;
          return (
              (scope.showUnmappedParams && isUnmapped) || !scope.showUnmappedParams
            ) &&
            (
              !scope.filterByDepartureStation ||
              StringHelperService.removeDiacritics(_.get(transfer, 'from.name', '').toString()).match(termToCheckDeparture)
            ) &&
            (
              !scope.filterByArrivalStation ||
              StringHelperService.removeDiacritics(_.get(transfer, 'to.name', '').toString()).match(termToCheckArrival) 
            ) &&
            (
              !scope.filterByVehicleType ||
              StringHelperService.removeDiacritics(_.get(transfer, 'type', '').toString()).match(termToCheckVehicleType)
            ) &&
            (
                !scope.filterByVehicleClass ||
                StringHelperService.removeDiacritics(_.get(transfer, 'class', '').toString()).match(termToCheckVehicleClass)
            ) &&
            (
                !scope.filterByOperator ||
                StringHelperService.removeDiacritics(_.get(transfer, 'companyName', '').toString()).match(termToCheckOperator)
            );
        });
      };

      scope.refresh = function () {
        scope.totalSelected = scope.transfers.filter(function (transfer) {
          return transfer.isSelected;
        }).length;
        scope.filterData();
        scope.pagination = {
          currentPage: 1,
          itemsPerPage: 8,
          totalItems: scope.filteredData.length
        };
        setPagingData(1);
      };

      scope.$watch('transfers', function () {
        scope.refresh();
      }, true);

      scope.$watch('filterByDepartureStation + filterByArrivalStation + filterByVehicleType + filterByVehicleClass + filterByOperator + showUnmappedParams', function () {
        scope.refresh();
      }, true);

      scope.$watch('currentPage', function () {
        setPagingData(scope.pagination.currentPage);
      });

      scope.pageChanged = function () {
        setPagingData(scope.pagination.currentPage);
      };

      scope.clearFilter = function () {
        scope.filterByDepartureStation = '';
        scope.filterByArrivalStation = '';
        scope.filterByVehicleType = '';
        scope.filterByVehicleClass = '';
        scope.filterByOperator = '';
      };
    }
  };
}

'use strict';

/*
exported
supplierApiStationDataCreateLineDirective
 */

function supplierApiStationDataCreateLineDirective() {
  return {
    restrict: 'AE',
    templateUrl: 'views/supplier-api/supplier-api-station-data-create-line.directive.html',
    scope: {
      supplierStation: '=supplierStation',
      station: '=station',
      newData: '=newData',
      isMultiStation: '@'
    }
  };

}

'use strict';

/* exported
supplierApiCreateLineCtrl
 */

function supplierApiCreateLineCtrl($scope, $rootScope, UsersRestangular, InventoryRestangular, $uibModalInstance, supplierApiName, transfer, fromCity, toCity, fromStationWithMapping, toStationWithMapping, companyWithMapping, vehicleClassWithMapping, vehicleTypeWithMapping) {
  $scope.fromCity = fromCity;
  $scope.toCity = toCity;
  $scope.transfer = transfer;
  $scope.fromStationNewData = {};
  $scope.toStationNewData = {};
  $scope.submitLock = false;
  $scope.multiStations = [];
  $scope.notMappedVehicleError = false;

  if (supplierApiName === '12go') {  
    if (vehicleClassWithMapping && vehicleClassWithMapping[0]) {
      $scope.mockedLineClass = vehicleClassWithMapping[0].vehicleClassId;
    } else {
      $scope.notMappedVehicleError = true;
      $scope.mockedLineClass = '<span style="color: red;">not mapped</span>';
    }

    if (vehicleTypeWithMapping && vehicleTypeWithMapping[0]) {
      $scope.mockedLineType = vehicleTypeWithMapping[0].bookawayData.vehicleTypeId;
    } else {
      $scope.notMappedVehicleError = true;
      $scope.mockedLineType = '<span style="color: red;">not mapped</span>';
    }
  }

  function extractStationDataAsPayload (station, fromCityId, toCityId){
    var result = {supplierStationId: station.supplierStation.id};
    if (station.bookawayStation && station.bookawayStation._id){
      result.stationId= station.bookawayStation._id;
    } else {
      result.name = _.get(station, 'newData.name');
      result.address =  _.get(station, 'newData.address');
      result.cityId = station.supplierStation.cityOrigin === 'from' ? fromCityId : toCityId;
      const toStationLat = _.get(station, 'newData.latitude');
      const toStationLong = _.get(station, 'newData.longitude');
      if (toStationLat && toStationLong) {
        result.loc = [toStationLong, toStationLat];
      }
    }
    result.timeOffsetInMinutes = station.supplierStation.timeOffsetInMinutes;
    result.isBoarding = station.supplierStation.isBoarding;
    result.isDropoff = station.supplierStation.isDropoff;
    return result;
  }

  function getMultiStationsData(inputStations, outputStations ){
    (inputStations || []).forEach(function(station){
      var obj = {
        supplierStation: station,
        newData: {},
      };
      InventoryRestangular.all('v1/supplier-api/' + supplierApiName).get('station-mapping/by-supplier-mapping/' + station.id)
        .then(function(stationWithMapping){
          if (stationWithMapping){
            InventoryRestangular.one('stations', stationWithMapping.stationId).get()
              .then(function (result) {
                obj.bookawayStation = result;
              });
          }
        });
      outputStations.push(obj);
    });
  }

  if (fromStationWithMapping) {
    InventoryRestangular.one('stations', fromStationWithMapping.stationId).get()
      .then(function (result) {
        $scope.fromStation = result;
      });
  }

  if (toStationWithMapping) {
    InventoryRestangular.one('stations', toStationWithMapping.stationId).get()
      .then(function (result) {
        $scope.toStation = result;
      });
  }

  getMultiStationsData(transfer.multiStations, $scope.multiStations);

  if (companyWithMapping) {
    UsersRestangular.one('companies', companyWithMapping.companyId).get()
      .then(function (result) {
        $scope.company = result;
      });
  }

  $scope.close = function() {
    $uibModalInstance.dismiss('closed');
  };

  $scope.submit = function () {
    if ($scope.notMappedVehicleError) {
      $rootScope.$emit('notify', {type: 'error', title: 'Create line', message: `Bookaway and ${supplierApiName} vehicle type or class for this transfer is not mapped.`});
      return;
    }
    try {
      if ($scope.submitLock) {
        console.log('supplier api operation in progress.. submit is locked');
        return;
      } else {
        $scope.submitLock = true;
        const payload = {
          fromStation: {
            supplierStationId: transfer.fromStation.id
          },
          toStation: {
            supplierStationId: transfer.toStation.id,
          },
          toStationTimeOffsetInMinutes: transfer.toStation.timeOffsetInMinutes || 0,
          type: $scope.mockedLineType || $scope.type,
          lineClass: $scope.mockedLineClass || $scope.lineClass,
          companyId: $scope.company._id,
          transferMapping: [transfer.transferMapping],
        };
        if (fromStationWithMapping) {
          payload.fromStation.stationId = fromStationWithMapping.stationId;
        } else {
          payload.fromStation.name = _.get($scope, 'fromStationNewData.name');
          payload.fromStation.address = _.get($scope, 'fromStationNewData.address');
          payload.fromStation.cityId = fromCity._id;
          const fromStationLat = _.get($scope, 'fromStationNewData.latitude');
          const fromStationLong = _.get($scope, 'fromStationNewData.longitude');
          if (fromStationLat && fromStationLong) {
            payload.fromStation.loc = [fromStationLong, fromStationLat];
          }
        }
        if (toStationWithMapping) {
          payload.toStation.stationId = toStationWithMapping.stationId;
        } else {
          payload.toStation.name = _.get($scope, 'toStationNewData.name');
          payload.toStation.address = _.get($scope, 'toStationNewData.address');
          payload.toStation.cityId = toCity._id;
          const toStationLat = _.get($scope, 'toStationNewData.latitude');
          const toStationLong = _.get($scope, 'toStationNewData.longitude');
          if (toStationLat && toStationLong) {
            payload.toStation.loc = [toStationLong, toStationLat];
          }
        }

        payload.multiStations = $scope.multiStations.map(function(station){return extractStationDataAsPayload(station, fromCity._id, toCity._id);});

        InventoryRestangular.all('v1/supplier-api/' + supplierApiName + '/create-supplier-api-line').post(payload)
          .then(function () {
            $uibModalInstance.close({status: 'success'});
            $scope.submitLock = false;
          }).catch(function (err) {
          $uibModalInstance.close({status: 'error', err: err});
          $scope.submitLock = false;
        });
      }
    } catch (err){
      $uibModalInstance.close({status: 'error', err: err});
      $scope.submitLock = false;
    }
  };

  $scope.aToBTransfers = [transfer];
}

'use strict';

/* exported
supplierApiMapLineCtrl
 */

function supplierApiMapLineCtrl($scope, $rootScope, UsersRestangular, InventoryRestangular, $uibModalInstance, supplierApiName, multiStations, fromCity, toCity, line, fromStationMappingObject, toStationMappingObject, toStationTimeOffsetInMinutes, vehicleClassWithMapping, vehicleTypeWithMapping, transferMappings) {
  $scope.fromCity = fromCity;
  $scope.toCity = toCity;
  $scope.line = line;
  $scope.fromStationNewData = {};
  $scope.toStationNewData = {};
  $scope.submitLock = false;
  $scope.multiStations = [];

  $scope.isOne2Go = supplierApiName === '12go';

  if ($scope.isOne2Go) {
    $scope.notEqualVehicleClass = !vehicleClassWithMapping.find((vClass) => vClass.vehicleClassId === line.lineClass);
    $scope.notEqualVehicleType = !vehicleTypeWithMapping.find((vType) => vType.bookawayData.vehicleTypeId === line.type);
    $scope.supplierVehicleType = transferMappings[0].vehicleType;
    $scope.supplierVehicleClass = transferMappings[0].vehicleClass;
  }

  if (fromCity._id.toString() === line.stationA.city._id.toString()) {
    $scope.fromStation = line.stationA;
    $scope.toStation = line.stationB;
  } else {
    $scope.fromStation = line.stationB;
    $scope.toStation = line.stationA;
  }

  function extractStationDataAsPayload(station, fromCityId, toCityId) {
    var result = {supplierStationId: station.supplierStation.id};
    if (station.bookawayStation && station.bookawayStation._id) {
      result.stationId = station.bookawayStation._id;
    } else {
      result.name = _.get(station, 'newData.name');
      result.address = _.get(station, 'newData.address');
      result.cityId = station.supplierStation.cityOrigin === 'from' ? fromCityId : toCityId;
      const toStationLat = _.get(station, 'newData.latitude');
      const toStationLong = _.get(station, 'newData.longitude');
      if (toStationLat && toStationLong) {
        result.loc = [toStationLong, toStationLat];
      }
    }
    result.timeOffsetInMinutes = station.supplierStation.timeOffsetInMinutes;
    result.isBoarding = station.supplierStation.isBoarding;
    result.isDropoff = station.supplierStation.isDropoff;
    return result;
  }


  function getMultiStationsData(inputStations, outputStations) {
    var fromMappings = _.get(fromStationMappingObject, 'mapping.supplierMapping', []);
    fromMappings = fromMappings.map(function (item) {return item.stationId;});
    var toMappings = _.get(toStationMappingObject, 'mapping.supplierMapping', []);
    toMappings = toMappings.map(function (item) {return item.stationId;});
    (inputStations || []).filter(function (station) {
      return !fromMappings.includes(station.id) && !toMappings.includes(station.id);
    }).forEach(function (station) {
      var obj = {
        supplierStation: station,
        newData: {},
      };
      InventoryRestangular.all('v1/supplier-api/' + supplierApiName).get('station-mapping/by-supplier-mapping/' + station.id)
        .then(function (stationWithMapping) {
          if (stationWithMapping) {
            InventoryRestangular.one('stations', stationWithMapping.stationId).get()
              .then(function (result) {
                obj.bookawayStation = result;
              });
          }
        });
      outputStations.push(obj);
    });
  }

  getMultiStationsData(multiStations, $scope.multiStations);

  $scope.close = function () {
    $uibModalInstance.close(false);
  };

  const notEqualVehicleClass = !vehicleClassWithMapping || !vehicleClassWithMapping[0] || vehicleClassWithMapping[0].vehicleClassId !== line.lineClass;
  const notEqualVehicleType = !vehicleTypeWithMapping || !vehicleTypeWithMapping[0] || vehicleTypeWithMapping[0].bookawayData.vehicleTypeId !== line.type;

  $scope.submit = function () {
    if ($scope.isOne2Go && ($scope.notEqualVehicleClass || $scope.notEqualVehicleType)) {
      $rootScope.$emit('notify', {type: 'error', title: 'Map line', message: `Bookaway and ${supplierApiName} vehicle type or class for this line is not mapped.`});
      return;
    } else {
      const result = $scope.multiStations.map(function (station) {
        return extractStationDataAsPayload(station, fromCity._id, toCity._id);
      });
      $uibModalInstance.close({multiStationsResult: result, toStationTimeOffset: toStationTimeOffsetInMinutes});
    }
  };
}

'use strict';

/*
exported
countriesTransferMappingDirective
 */

function countriesTransferMappingDirective($rootScope, StringHelperService, SupplierApiService, $timeout) {
  return {
    restrict: 'AE',
    // require: 'ngModel',
    templateUrl: 'views/supplier-api/countries-transfer-mapping-table.directive.html',
    scope: {
      transfers: '=transfers',
      page: '=page',
      supplier: '=supplier',
    },
    link: function (scope, iElement, iAttrs, ngModelCtrl) {
      scope.withPagination = true;
      scope.totalTransfers = scope.transfers;
      const itemsPerPage = 50;
  
      scope.isCreateBtnDisabled = true;
      scope.googleSheetLink = null;
      scope.showMappings = !!(scope.transfers[0] && scope.transfers[0].mappings);

      // withPagination
      if (scope.withPagination) {
        scope.pagination = {
          itemsPerPage,
          currentPage: scope.page,
          totalItems: scope.transfers.meta.total,
        }
  
        scope.$watch('transfers', function () {
          const list = _.filter(scope.transfers, item => item.selected);
          if (!list.length)
            scope.isCreateBtnDisabled = true;
          else
            scope.isCreateBtnDisabled = false;
        }, true);
  
        scope.pageChanged = function () {
          $rootScope.$broadcast('mappingPageChanged', scope.pagination.currentPage)
        };
        scope.checkFilterByMappings = function (filterByMappings) {
          switch (filterByMappings) {
            case 'all':
              scope.totalTransfers = scope.transfers;
              break;
            case 'notmapped':
              scope.totalTransfers = scope.transfers.filter((el) => el.mappings[0] !== el.mappings[1])
              break;
            case 'fullmapped':
              scope.totalTransfers = scope.transfers.filter((el) => el.mappings[0] === el.mappings[1])
              break;
            default:
              scope.totalTransfers = scope.transfers;
              break;
          }
          return scope.totalTransfers;
        }
        if (SupplierApiService.filterByMappings) {
          scope.checkFilterByMappings(SupplierApiService.filterByMappings);
        }

        $rootScope.$on('filterByMappingsChange', function (event, data) {
          scope.checkFilterByMappings(data);
        })

        scope.clearSelected = function () {
          scope.transfers.forEach(function (transfer) {
            transfer.isSelected = false;
          });
        };
  
        scope.toggleSelected = function (allSelected) {
          angular.forEach(scope.transfers, function (item) {
            item.selected = !allSelected;
          });
        };
  
      } else {
        // no pagination
        function setPagingData(page) {
          var pagedData = scope.filteredData.slice(
            (page - 1) * scope.pagination.itemsPerPage,
            page * scope.pagination.itemsPerPage
          );
          scope.visibleSupplierTransfers = pagedData;
          scope.pagination.currentPage = page;
        }

        scope.mappingsFilterDropdownOptions = ['All', 'Fully mapped', 'Not mapped'];

        scope.filters = {
          filterByDepartureCity: '',
          filterByArrivalCity: '',
          filterByOperator: '',
          filterByVehicle: '',
          filterByMappings: scope.mappingsFilterDropdownOptions[0],
        }
  
        scope.filterData = function () {
          var termToCheckDeparture = new RegExp(scope.filters.filterByDepartureCity || '', 'i');
          var termToCheckArrival = new RegExp(scope.filters.filterByArrivalCity || '', 'i');
          var termToCheckOperator = new RegExp(scope.filters.filterByOperator || '', 'i');
          var termToCheckVehicle = new RegExp(scope.filters.filterByVehicle || '', 'i');
          scope.filteredData = scope.totalTransfers.filter(function (transfer) {
            return (
              !scope.filters.filterByDepartureCity ||
              StringHelperService.removeDiacritics(_.get(transfer, 'fromCity.name', '').toString()).match(termToCheckDeparture) ||
              StringHelperService.removeDiacritics(_.get(transfer, 'fromCity.id', '').toString()).match(termToCheckDeparture) ||
              StringHelperService.removeDiacritics(_.get(transfer, 'fromCity.address', '').toString()).match(termToCheckDeparture)
            ) &&
              (
                !scope.filters.filterByArrivalCity ||
                StringHelperService.removeDiacritics(_.get(transfer, 'toCity.name', '').toString()).match(termToCheckArrival) ||
                StringHelperService.removeDiacritics(_.get(transfer, 'toCity.id', '').toString()).match(termToCheckArrival) ||
                StringHelperService.removeDiacritics(_.get(transfer, 'toCity.address', '').toString()).match(termToCheckArrival)
              ) &&
              (
                !scope.filters.filterByOperator ||
                StringHelperService.removeDiacritics(_.get(transfer, 'company.name', '').toString()).match(termToCheckOperator) ||
                StringHelperService.removeDiacritics(_.get(transfer, 'company.id', '').toString()).match(termToCheckOperator)
              ) &&
              (
                !scope.filters.filterByVehicle ||
                StringHelperService.removeDiacritics(_.get(transfer, 'vehicleType', '').toString()).match(termToCheckVehicle) ||
                StringHelperService.removeDiacritics(_.get(transfer, 'vehicle_type_id', '').toString()).match(termToCheckVehicle)
              ) &&
              (
                scope.filters.filterByMappings === scope.mappingsFilterDropdownOptions[0] ||
                (scope.filters.filterByMappings === scope.mappingsFilterDropdownOptions[1] &&
                  transfer.mappings[0] >= transfer.mappings[1]) ||
                (scope.filters.filterByMappings === scope.mappingsFilterDropdownOptions[2] &&
                  transfer.mappings[0] < transfer.mappings[1])
              );
          });
        };
  
        scope.refresh = function () {
          scope.totalSelected = scope.totalTransfers.filter(function (transfer) {
            return transfer.isSelected;
          }).length;
          scope.filterData();
          scope.pagination = {
            currentPage: 1,
            itemsPerPage,
            totalItems: scope.filteredData.length
          };
          setPagingData(1);
        };
  
        scope.$watch('transfers', function () {
          //scope.refresh();
          const list = _.filter(scope.totalTransfers, item => item.selected);
          if (!list.length)
            scope.isCreateBtnDisabled = true;
          else
            scope.isCreateBtnDisabled = false;
        }, true);
  
        scope.$watch('[SupplierApiService.selectedFromCountry, SupplierApiService.selectedToCountry]', function () {
            scope.fromCountry = SupplierApiService.selectedFromCountry.name;
            scope.toCountry = SupplierApiService.selectedToCountry.name;
        }, true);
  
        scope.$watch('filters', function () {
          scope.refresh();
        }, true);

        scope.filtersChange = function() {
          scope.refresh();
        }
  
        scope.$watch('currentPage', function () {
          setPagingData(scope.pagination.currentPage);
        });
  
        scope.$watch('SupplierApiService.selectedOperator', function () {
          scope.showOperator = !SupplierApiService.selectedOperator;
        });
  
        scope.$watch('[pagination.currentPage, visibleSupplierTransfers]', function () {
          const list = _.filter(scope.visibleSupplierTransfers, item => item.selected);
          if (scope.visibleSupplierTransfers.length === list.length) {
            scope.allSelected = true;
          }
          else {
            scope.allSelected = false;
          }
        }, true);
  
        scope.$watch('pagination.itemsPerPage', function () {
          setPagingData(1);
        }, true);
  
        scope.pageChanged = function () {
          setPagingData(scope.pagination.currentPage);
        };
  
        scope.clearSelected = function () {
          scope.totalTransfers.forEach(function (transfer) {
            transfer.isSelected = false;
          });
        };
  
        scope.toggleSelected = function (allSelected) {
          angular.forEach(scope.visibleSupplierTransfers, function (item) {
            item.selected = !allSelected;
          });
        };
      }

      scope.getMapLinkForAddress = function (query) {
        const encodedQuery = encodeURIComponent(query);
        const result = 'https://www.google.com/maps/search/' + encodedQuery;
        return result;
      };

      scope.createMapping = function () {
        scope.googleSheetLink = null;
        scope.isCreateBtnDisabled = true;
        const selectedItems = _.filter(scope.withPagination ? scope.totalTransfers : scope.visibleSupplierTransfers, item => item.selected);
        const mappings = _.map(selectedItems, item => ({ supplierCompanyId: item.company.id, supplierFromCityId: item.fromCity.id, supplierToCityId: item.toCity.id, vehicleType: item.vehicleType, vehicleClass: item.vehicleClass }));
        const mappingReport = _.map(selectedItems, item => ({ supplierCompanyName: item.company.name || SupplierApiService.selectedOperator.name, supplierFromCityName: item.fromCity.name, supplierToCityName: item.toCity.name, vehicleType: item.vehicleType }));
        angular.forEach(scope.transfers, function (item) {
          item.selected = false;
        });
        SupplierApiService.performBulkMapping(mappings, mappingReport)
          .then(function (data) {
            if (data) {
              scope.googleSheetLink = data;
              angular.forEach(scope.transfers, function (item) {
                item.selected = false;
              });
              $timeout(function () { scope.isCreateBtnDisabled = false; }, 5000);
            } else {
              scope.isCreateBtnDisabled = false;
            }
          });
      };
    }
  }  
}
